<template>
	<v-dialog v-model="editMode" persistent max-width="600px">
		<template v-slot:activator="{ on }">
			<v-btn icon color="warning" v-on="on">
				<v-icon>mdi-pencil</v-icon>
			</v-btn>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">{{ $t('offers.mainInformation') }}</span>
				<v-spacer />
				<v-btn icon @click="switchEditMode()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-row>
						<v-col cols="12" sm="6">
							<v-text-field
								v-model="name.first"
								:label="$t('auth.firstName')"
								:rules="rules.name.first"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>

						<v-col cols="12" sm="6">
							<v-text-field
								v-model="name.last"
								:label="$t('auth.lastName')"
								:rules="rules.name.last"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" text rounded plain @click="callUpdate()">
					{{ $t('settings.edit') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'UserInfoForm',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			loading: true,
			editMode: false,
			valid: false,
			name: {
				first: '',
				last: '',
			},
			rules: {
				name: {
					first: [
						(v) => !!v || this.$t('rules.required'),
						(v) => (v && v.length <= this.firstNameMaxLength) || this.$t('rules.length', { length: this.firstNameMaxLength }),
					],
					last: [
						(v) => !!v || this.$t('rules.required'),
						(v) => (v && v.length <= this.lastNameMaxLength) || this.$t('rules.length', { length: this.lastNameMaxLength }),
					],
				},
			},
		}
	},
	computed: {
		firstNameMaxLength() {
			return 64
		},
		lastNameMaxLength() {
			return 128
		},
		...mapGetters({
			user: 'user/currentUser',
		}),
	},
	created() {
		this.name = this.user.name
		this.loading = false
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		callUpdate() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.updateUserInfo({ name: this.name })
				.then(({ success }) => {
					if (success) this.switchEditMode()
				})
				.then(() => {
					this.loading = false
				})
		},
		...mapActions('settings', ['updateUserInfo']),
	},
}
</script>
